













import { Component, Vue } from 'vue-property-decorator'
import Modal from 'va/components/Modal.vue'
import Header from '@/components/organisms/Layout/Header.vue'
import SideBar from '@/components/organisms/Layout/SideBar.vue'
import Content from '@/components/organisms/Layout/Content.vue'
import menuStore from '@/store/Menu'

@Component({
  components: { Modal, Header, Content, SideBar },
})
export default class extends Vue {
  get isMenuVisible(): boolean {
    return menuStore.isVisible
  }
}
