import store from '@/store'
import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators'
import axios, { AxiosError } from 'axios'
import config from '@/config'

@Module({ dynamic: true, namespaced: true, name: 'auth', store })
class Mod extends VuexModule {
  // TODO: 未使用なので関連処理も合わせて削除したい
  isAuthed = false
  // TODO: 未使用なので関連処理も合わせて削除したい
  isExpiration = false
  // TODO: 未使用なので関連処理も合わせて削除したい
  isOverMaxGroups = false
  // 📝 GA用
  orgLoginId = ''

  constructor(module: Mod) {
    super(module)
    this.syncSessionStorage()
    this.syncLocalStorage()
  }

  private syncSessionStorage(): void {
    const storageData = sessionStorage.getItem('dashboardSessionStorage')
    if (storageData === null) {
      this.isAuthed = false
      return
    }

    const parsedStorageData = JSON.parse(storageData)
    this.isAuthed = parsedStorageData.auth.isAuthed
  }

  private syncLocalStorage(): void {
    const orgLoginId = localStorage.getItem('orgLoginId')
    if (orgLoginId === null) {
      this.orgLoginId = ''
      return
    }

    this.orgLoginId = orgLoginId
  }

  @Mutation
  setIsAuthed(isAuthed: boolean): void {
    this.isAuthed = isAuthed
  }

  @Mutation
  setIsExpiration(isExpiration: boolean): void {
    this.isExpiration = isExpiration
  }

  @Mutation
  setIsOverMaxGroups(isOverMaxGroups: boolean): void {
    this.isOverMaxGroups = isOverMaxGroups
  }

  @Mutation
  setOrgLoginId(orgLoginId: string): void {
    this.orgLoginId = orgLoginId
    if (orgLoginId !== '') {
      localStorage.setItem('orgLoginId', orgLoginId)
    } else {
      localStorage.removeItem('orgLoginId')
    }
  }

  @Action
  async checkAll(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-owners/all-check`
    try {
      await axios.post(url)
    } catch (e: unknown) {
      const axiosError = e as AxiosError
      const response = axiosError.response

      this.setIsExpiration(!!response?.data?.isExpiration)
      this.setIsOverMaxGroups(!!response?.data?.isOverMaxGroups)
      this.setIsAuthed(false)
    }
    this.setIsAuthed(true)
  }

  @Action
  async checkLoggedInExpiration(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/no-group-check/organization-owners/logged-in-expiration-check`
    try {
      await axios.post(url)
    } catch (e: unknown) {
      const axiosError = e as AxiosError
      const response = axiosError.response

      this.setIsExpiration(!!response?.data?.isExpiration)
      this.setIsAuthed(false)
    }
    this.setIsAuthed(true)
  }

  @Action
  async checkLoggedIn(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/no-contract/organization-owners/logged-in-check`
    try {
      await axios.post(url)
    } catch (e: unknown) {
      this.setIsAuthed(false)
    }
    this.setIsAuthed(true)
  }

  @Action
  saveOrgLoginId(orgLoginId: string): void {
    this.setOrgLoginId(orgLoginId)
  }

  @Action
  deleteOrgLoginId(): void {
    this.setOrgLoginId('')
  }
}

export default getModule(Mod)
