










import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/components/templates/Layout.vue'
import profileStore from '@/store/Profile'

@Component({
  name: 'app',
  components: { Layout },
})
export default class extends Vue {
  fixed = false
  mounted(): void {
    Vue.prototype.$fixApp = (fixed: boolean) => {
      this.fixed = fixed
    }
    profileStore.fetchProfile()
  }

  // モーダル表示時に画面を固定する処理
  fixApp(fixed: boolean): void {
    this.fixed = fixed
  }
}
