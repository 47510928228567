

































































import { Component, Vue } from 'vue-property-decorator'
import MenuItem from '@/components/molecules/Layout/SideMenuItem.vue'
import SubMenuItem from '@/components/molecules/Layout/SideMenuSubItem.vue'
import menuStore, { TMenuGroupItem } from '@/store/Menu'
import organizationUsersStore from '@/store/OrganizationUsers'

@Component({
  components: { MenuItem, SubMenuItem },
})
export default class extends Vue {
  menu = menuStore

  get isOrgOwner(): boolean {
    return organizationUsersStore.isOrgOwner
  }

  get groupIsActive(): boolean {
    return menuStore.groups.some((group) => group.active)
  }

  get personIsActive(): boolean {
    return menuStore.persons.some((person) => person.active)
  }

  get groups(): TMenuGroupItem[] {
    return menuStore.sortedGroups
  }
}
