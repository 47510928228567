import store from '@/store'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'

type TProfile = {
  name: string
  email: string
  groupName: string
  photoSrc?: string
}

@Module({ dynamic: true, namespaced: true, name: 'profile', store })
class Mod extends VuexModule {
  name = ''
  email = ''
  groupName = ''
  photoSrc = null

  @Mutation
  setState(data: TProfile): void {
    Object.assign(this, data)
  }

  /** プロフィール情報を読み込む */
  @Action({ commit: 'setState' })
  fetchProfile(): TProfile {
    return {
      name: '温藤 優作',
      email: 'ondo.yusaku@ond-care.com',
      groupName: 'OND`Care探偵事務所',
      photoSrc: require('@/assets/images/yusaku.png'),
    }
  }
}

export default getModule(Mod)
