














import { Component, Vue } from 'vue-property-decorator'
import Profile from '@/components/organisms/Layout/Profile.vue'
import SideMenu from '@/components/organisms/Layout/SideMenu.vue'
import SettingMenu from '@/components/organisms/Layout/SettingMenu.vue'
import Logout from '@/components/organisms/Layout/Logout.vue'
import UserGuideButton from '@/components/organisms/Layout/UserGuideButton.vue'
import organizationUsersStore from '@/store/OrganizationUsers'

@Component({
  components: { Profile, SideMenu, SettingMenu, Logout, UserGuideButton },
})
export default class extends Vue {
  get isOrgOwner(): boolean {
    return organizationUsersStore.isOrgOwner
  }

  async mounted(): Promise<void> {
    await Promise.all([organizationUsersStore.getCurrentRole()])
  }
}
