export default {
  main: {
    clientUrl: process.env.VUE_APP_MAIN_CLIENT_URL || '',
  },
  enterprise: {
    clientUrl: process.env.VUE_APP_ENTERPRISE_CLIENT_URL || '',
    apiUrl: process.env.VUE_APP_ENTERPRISE_API_URL || '',
    albPreSharedKey: process.env.VUE_APP_ENTERPRISE_ALB_PRE_SHARED_KEY || '',
  },
  purchase: {
    sbp: {
      merchantId: process.env.VUE_APP_SBP_MERCHANT_ID || '',
      serviceId: process.env.VUE_APP_SBP_SERVICE_ID || '',
      requestUrl: process.env.VUE_APP_SBP_REQUEST_URL || '',
      hashKey: process.env.VUE_APP_SBP_HASH_KEY || '',
      create: {
        successUrl: process.env.VUE_APP_SBP_CREATE_SUCCESS_URL || '',
        cancelUrl: process.env.VUE_APP_SBP_CREATE_CANCEL_URL || '',
        errorUrl: process.env.VUE_APP_SBP_CREATE_ERROR_URL || '',
        pageconUrl: process.env.VUE_APP_SBP_CREATE_PAGECON_URL || '',
      },
      cancel: {
        successUrl: process.env.VUE_APP_SBP_CANCEL_SUCCESS_URL || '',
        cancelUrl: process.env.VUE_APP_SBP_CANCEL_CANCEL_URL || '',
        errorUrl: process.env.VUE_APP_SBP_CANCEL_ERROR_URL || '',
        pageconUrl: process.env.VUE_APP_SBP_CANCEL_PAGECON_URL || '',
      },
      change: {
        successUrl: process.env.VUE_APP_SBP_CHANGE_SUCCESS_URL || '',
        cancelUrl: process.env.VUE_APP_SBP_CHANGE_CANCEL_URL || '',
        errorUrl: process.env.VUE_APP_SBP_CHANGE_ERROR_URL || '',
        createUrl: process.env.VUE_APP_SBP_CHANGE_CREATE_URL || '',
        createPageconUrl: process.env.VUE_APP_SBP_CREATE_PAGECON_URL || '',
        cancelPageconUrl: process.env.VUE_APP_SBP_CANCEL_PAGECON_URL || '',
      },
    },
  },
  googleAnalytics: {
    gaId: process.env.VUE_APP_GA4_MEASUREMENT_ID || '',
  },
}
