import store from '@/store'
import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators'
import axios from 'axios'
import config from '@/config'

@Module({ dynamic: true, namespaced: true, name: 'organizationUserAuth', store })
class Mod extends VuexModule {
  @Action
  async checkLoggedIn(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-users/check-logged-in`
    try {
      await axios.post(url)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  @Action
  async checkContract(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-users/check-contract`
    try {
      await axios.post(url)
    } catch (e: unknown) {
      console.error(e)
    }
  }
}

export default getModule(Mod)
