





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class extends Vue {
  @Prop({ default: '' }) router!: string
  @Prop({ default: '' }) icon!: string
  @Prop({ default: false }) active!: boolean
}
