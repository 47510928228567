import store from '@/store'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import axios from 'axios'
import _ from 'lodash'

export type TMenuGroupItem = { id: number | string; name: string; order?: number; active: boolean }
type TMenuPersonItem = {
  id: number | string
  groupId: number | string
  name: string
  active: boolean
  profilePhoto?: string
}
type TActiveFlg = { home: boolean; group: boolean; person: boolean; remind: boolean; settings: boolean }

@Module({ dynamic: true, namespaced: true, name: 'menu', store })
class Mod extends VuexModule {
  activeFlg = { home: false, group: false, person: false, remind: false, settings: false }
  groups: TMenuGroupItem[] = []
  persons: TMenuPersonItem[] = []

  get isOrginaization(): boolean {
    return _.filter(this.activeFlg, (v) => v).length > 0
  }

  get isVisible(): boolean {
    return this.isOrginaization
  }

  get sortedGroups(): TMenuGroupItem[] {
    return this.groups.sort((a, b) => {
      return (a.order ?? 0) - (b.order ?? 0)
    })
  }

  @Action
  setInvisible(): void {
    this.setActiveFlg({})
  }

  @Mutation
  setGroups(data: TMenuGroupItem[]): void {
    this.groups = data
  }

  @Mutation
  setPersons(data: TMenuPersonItem[]): void {
    this.persons = data
  }

  @Mutation
  setActiveFlg(data: Partial<TActiveFlg>): void {
    this.activeFlg.home = data.home || false
    this.activeFlg.group = data.group || false
    this.activeFlg.person = data.person || false
    this.activeFlg.remind = data.remind || false
    this.activeFlg.settings = data.settings || false
  }

  @Mutation
  activateGroup(groupId: string): void {
    this.groups = _.map(this.groups, (group) => {
      return _.set(group, 'active', group.id === groupId)
    })
  }

  @Action({ commit: 'setGroups' })
  async fetchGroups(): Promise<TMenuGroupItem[]> {
    const response = await axios.get('/groups')

    if (response.status !== 200) {
      throw `API response error status: ${response.status}`
    }

    return _.map(response.data, (data) => {
      return _.map(data.organizationGroups, (orgGroup) => {
        return {
          id: data.id,
          name: data.name,
          order: orgGroup.order,
          active: false,
        }
      })
    }).flat()
  }

  @Action({ commit: 'setPersons' })
  async fetchUsers({ groupId }: { groupId: string }): Promise<void> {
    const { status, data: group } = await axios.get(`/groups/${groupId}`)

    if (status !== 200) {
      throw `API response error status: ${status}`
    }

    return group.users.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (user: any): TMenuPersonItem => ({
        id: user.id,
        groupId: group.id,
        name: `${user.lastName} ${user.firstName}`,
        active: false,
        profilePhoto: user.profilePhoto,
      })
    )
  }

  @Action({})
  async fetchGroupAndUsers({ groupId, personId }: { groupId: string; personId: string }): Promise<void> {
    const { status, data: group } = await axios.get(`/groups/${groupId}`)

    if (status !== 200) {
      throw `API response error status: ${status}`
    }

    this.setGroups([{ id: group.id, name: group.name, order: undefined, active: true }])
    const userItems = group.users.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (user: any): TMenuPersonItem => ({
        id: user.id,
        groupId: group.id,
        name: `${user.lastName} ${user.firstName}`,
        active: user.id == personId,
        profilePhoto: user.profilePhoto,
      })
    )
    this.setPersons(userItems)
  }
}

export default getModule(Mod)
