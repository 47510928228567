











import { Component, Vue } from 'vue-property-decorator'
import profileStore from '@/store/Profile'

@Component({})
export default class extends Vue {
  profile = profileStore
}
