var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"sidebar-menu",attrs:{"data-widget":"tree"}},[_c('MenuItem',{attrs:{"active":_vm.menu.activeFlg.home,"icon":"fa fa-fw fa-home","router":"/"}},[_vm._v("Home")]),_c('MenuItem',{class:{ clickable: _vm.menu.groups.length > 0 },attrs:{"active":_vm.menu.activeFlg.group,"data-toggle":"collapse","href":"#collapseGroup","aria-controls":"collapseGroup","aria-expanded":"false","icon":"fa fa-fw fa-users"}},[_vm._v(" Group ")]),_c('li',{staticClass:"collapse",class:{ in: _vm.groupIsActive },style:({ 'max-height': '200px', height: _vm.groupIsActive ? undefined : 0 }),attrs:{"id":"collapseGroup"}},[_c('ul',{staticClass:"sidebar-menu"},_vm._l((_vm.groups),function(ref,i){
var id = ref.id;
var name = ref.name;
var active = ref.active;
return _c('SubMenuItem',{key:'groups' + i,attrs:{"icon":'fa fa-fw ' + (active ? 'fa-circle' : 'fa-circle-o'),"router":'/groups/' + id}},[_vm._v(" "+_vm._s(name)+" ")])}),1)]),_c('MenuItem',{class:{ clickable: _vm.menu.persons.length > 0 },attrs:{"active":_vm.menu.activeFlg.person,"icon":"fa fa-fw fa-user","data-toggle":"collapse","href":"#collapsePerson","aria-controls":"collapsePerson","aria-expanded":"false"}},[_vm._v(" Person ")]),_c('li',{staticClass:"collapse",class:{ in: _vm.personIsActive },style:({ 'max-height': '200px', height: _vm.personIsActive ? undefined : 0 }),attrs:{"id":"collapsePerson"}},[_c('ul',{staticClass:"sidebar-menu"},_vm._l((_vm.menu.persons),function(ref,i){
var id = ref.id;
var groupId = ref.groupId;
var name = ref.name;
var active = ref.active;
return _c('SubMenuItem',{key:'persons' + i,attrs:{"icon":'fa fa-fw ' + (active ? 'fa-circle' : 'fa-circle-o'),"router":'/groups/' + groupId + '/persons/' + id}},[_vm._v(" "+_vm._s(name)+" ")])}),1)]),(_vm.isOrgOwner)?_c('MenuItem',{attrs:{"active":_vm.menu.activeFlg.remind,"icon":"fa fa-fw fa-bullhorn","router":"/remind"}},[_vm._v(" Remind ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }