





























































import { Component, Vue, Watch } from 'vue-property-decorator'
import MenuItem from '@/components/molecules/Layout/SideMenuItem.vue'
import SubMenuItem from '@/components/molecules/Layout/SideMenuSubItem.vue'
import menuStore from '@/store/Menu'

@Component({
  components: { MenuItem, SubMenuItem },
})
export default class extends Vue {
  menu = menuStore
  activeMenu = {
    contract: false,
    groupSetting: false,
    groupReordering: false,
    orgUserSetting: false,
    alertSetting: false,
    questionItemSetting: false,
  }
  @Watch('$route')
  onChangeRoute(): void {
    this.activeMenu.contract = this.$route.name === 'Contract'
    this.activeMenu.groupSetting = this.$route.name === 'GroupSetting'
    this.activeMenu.groupReordering = this.$route.name === 'GroupReordering'
    this.activeMenu.orgUserSetting = this.$route.name === 'OrgUserSetting'
    this.activeMenu.alertSetting = this.$route.name === 'AlertSetting'
    this.activeMenu.questionItemSetting = this.$route.name === 'QuestionItemSetting'
  }
}
