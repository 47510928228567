import config from '@/config'
import store from '@/store'
import axios, { AxiosError } from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import authStore from '@/store/Auth'

export const ErrorMsg = {
  responseError: (status: number) => 'API response error status:' + status,
  CheckEmailError:
    "OND'Careアカウント未作成のユーザーを招待しています。\n先にアプリストアからOND'Careをダウンロードしアカウントを作成していただく\n必要がございます。",
  InvitationToViewerError: '組織閲覧者の招待に失敗しました',
  FetchOrganizationUsersFailureError: '組織ユーザ取得に失敗しました',
  GetCurrentRoleFailureError: '現在の組織権限取得に失敗しました',
  FailedToDeleteOrgViewerError: '組織閲覧者の削除に失敗しました',
} as const

export const Status = {
  WAITING: 'waiting',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  DELETED: 'deleted',
  EXPIRED: 'expired',
} as const
export type StatusType = typeof Status[keyof typeof Status]

export const Role = {
  OWNER: 'owner',
  VIEWER: 'viewer',
} as const
export type RoleType = typeof Role[keyof typeof Role]

export type InvitedCheckedViewer = { name: string }
export type OrganizationUser = {
  id: string
  name: string
  email: string
  role: RoleType
  status: StatusType
}
export type OrganizationOwner = OrganizationUser
export type OrganizationViewer = OrganizationUser

@Module({ dynamic: true, namespaced: true, name: 'organizationUsers', store })
class Mod extends VuexModule {
  currentRole: RoleType | null = null
  invitedCheckedViewer: InvitedCheckedViewer = { name: '' }
  organizationUsers: OrganizationUser[] = []

  get isOrgOwner(): boolean {
    return this.currentRole === Role.OWNER
  }

  @Mutation
  setCurrentRole(role: RoleType): void {
    this.currentRole = role
  }

  @Mutation
  setOrganizationUsers(orgUsers: OrganizationUser[]): void {
    this.organizationUsers = orgUsers
  }

  @Mutation
  setInvitedCheckedViewer(viewer: InvitedCheckedViewer): void {
    this.invitedCheckedViewer = viewer
  }

  @Action({ commit: 'setCurrentRole', rawError: true })
  async getCurrentRole(): Promise<RoleType> {
    try {
      const res = await axios.get(`${config.enterprise.apiUrl}/organization-users/role`)
      return res.data.role
    } catch (e) {
      throw new Error(ErrorMsg.GetCurrentRoleFailureError)
    }
  }

  @Action({ commit: 'setOrganizationUsers', rawError: true })
  async fetchOrganizationUsers(): Promise<OrganizationUser[]> {
    try {
      const res = await axios.get(`${config.enterprise.apiUrl}/organization-users`)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchOrganizationUsersFailureError)
    }
  }

  @Action({ commit: 'setInvitedCheckedViewer', rawError: true })
  async checkEmail({ email }: { email: string }): Promise<InvitedCheckedViewer> {
    try {
      const res = await axios.post(`${config.enterprise.apiUrl}/users/check-email`, { email })
      const name = res.data.name as string
      return { name }
    } catch (e) {
      throw new Error(ErrorMsg.CheckEmailError)
    }
  }

  @Action({ rawError: true })
  async inviteViewer({ email }: { email: string }): Promise<void> {
    try {
      await axios.post(`${config.enterprise.apiUrl}/organization-users/invite-viewer`, { email })
    } catch (e) {
      throw new Error(ErrorMsg.InvitationToViewerError)
    }
  }

  @Action({ rawError: true })
  async deleteOrgViewer(orgViewerId: string): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-viewers/delete`
    try {
      await axios.post(url, { orgViewerId: orgViewerId })
    } catch (e) {
      throw new Error(ErrorMsg.FailedToDeleteOrgViewerError)
    }
  }

  @Action({ rawError: true })
  async login({ orgLoginId, email, password }: { orgLoginId: string; email: string; password: string }): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-users/login`
    try {
      await axios.post(url, { orgLoginId, email, password })
      authStore.setIsAuthed(true)
      authStore.saveOrgLoginId(orgLoginId)
    } catch (e) {
      throw ErrorMsg.responseError((e as AxiosError).response?.status || 500)
    }
  }

  @Action({ rawError: true })
  async logout(): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-users/logout`
    try {
      await axios.post(url)
    } catch (e) {
      throw ErrorMsg.responseError((e as AxiosError).response?.status || 500)
    } finally {
      // NOTE: sessionStorage.clear()とすると、なぜか画面遷移のタイミングでクリア前の値が復活するため
      // 未認証状態に設定し直すことでログアウト（レスポンスが401だった場合と同様）
      authStore.setIsAuthed(false)
      authStore.deleteOrgLoginId()
    }
  }
}

export default getModule(Mod)
