import 'va/lib/css'
import 'va/lib/script'
import 'va/lib/global'
import './assets/styles/base.css'
import '@/libs/ChartCenterPlugin'
import { Role as OrgUserRole } from '@/store/OrganizationUsers'
import config from '@/config'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import EventBus from 'va/lib/eventBus.js'
import axios from 'axios'
import VueGtag from 'vue-gtag'

axios.defaults.headers.common['x-pre-shared-key'] = config.enterprise.albPreSharedKey
axios.defaults.baseURL = config.enterprise.apiUrl
axios.defaults.withCredentials = true
// TODO: もう少し動きが分かりやすくなるように設計を見直したい
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const ignoreUrls = [/\/users\/authentication$/, /\/organizations\/register$/]
    const isIgnoreUrl = (responseUrl, urls) => urls.some((url) => responseUrl.search(url) >= 0)
    const responseUrl = error.response.request.responseURL
    if (error.response.status === 401 && !isIgnoreUrl(responseUrl, ignoreUrls)) {
      if (error.response.data?.role === OrgUserRole.VIEWER) {
        const path = error.response.data?.isExpiration ? '/error' : '/login'
        router.push(path)
        return Promise.reject(error)
      }

      const path = error.response.data?.isExpiration ? '/expiration-of-contract' : '/login'
      router.push(path)
      return Promise.reject(error)
    } else if (error.response.status === 440) {
      if (error.response.data?.role === OrgUserRole.VIEWER) {
        router.push('/error')
        return Promise.reject(error)
      }

      router.push('/over-max-groups')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$bus = EventBus
Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.use(
  VueGtag,
  {
    config: {
      id: config.googleAnalytics.gaId,
      params: {
        send_page_view: true,
      },
    },
  },
  router
)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
