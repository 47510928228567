import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import authStore from '@/store/Auth'
import orgUserAuthStore from '@/store/OrganizationUserAuth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: { gtag: true },
  },
  {
    path: '/groups/:id',
    name: 'Group',
    component: () => import('@/views/Group.vue'),
    meta: { gtag: true },
  },
  {
    path: '/groups/:groupId/persons/:id',
    name: 'Person',
    component: () => import('@/views/Person.vue'),
    meta: { gtag: true },
  },
  {
    path: '/remind',
    name: 'Remind',
    component: () => import('@/views/Remind.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/settings/contract',
    name: 'Contract',
    component: () => import('@/views/Contract.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/settings/group',
    name: 'GroupSetting',
    component: () => import('@/views/GroupSetting.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/settings/group/reordering',
    name: 'GroupReordering',
    component: () => import('@/views/GroupReordering.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/settings/org-user',
    name: 'OrgUserSetting',
    component: () => import('@/views/OrgUserSetting.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/settings/alert',
    name: 'AlertSetting',
    component: () => import('@/views/AlertSetting.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/settings/question-item',
    name: 'QuestionItemSetting',
    component: () => import('@/views/QuestionItemSetting.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/contract/receipt',
    name: 'ContractReceipt',
    component: () => import('@/views/ContractReceipt.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/create',
    name: 'PurchaseContractCreate',
    component: () => import('@/views/Purchase/Contract/Create/Index.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/create/cancel',
    name: 'PurchaseContractCreateCancel',
    component: () => import('@/views/Purchase/Contract/Create/Cancel.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/create/error',
    name: 'PurchaseContractCreateError',
    component: () => import('@/views/Purchase/Contract/Create/Error.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/create/complete',
    name: 'PurchaseContractCreateComplete',
    component: () => import('@/views/Purchase/Contract/Create/Complete.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/cancel',
    name: 'PurchaseContractCancelRequest',
    component: () => import('@/views/Purchase/Contract/Cancel/Index.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/cancel/complete',
    name: 'PurchaseContractCancelComplete',
    component: () => import('@/views/Purchase/Contract/Cancel/Complete.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/cancel/cancel',
    name: 'PurchaseContractCancelCancel',
    component: () => import('@/views/Purchase/Contract/Cancel/Cancel.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/cancel/error',
    name: 'PurchaseContractCancelError',
    component: () => import('@/views/Purchase/Contract/Cancel/Error.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/change/select-plan',
    name: 'PurchaseContractChangeSelectPlan',
    component: () => import('@/views/Purchase/Contract/Change/SelectPlan.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/change/change-cancel',
    name: 'PurchaseContractChangeChangeCancel',
    component: () => import('@/views/Purchase/Contract/Change/ChangeCancel.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/change/change-create',
    name: 'PurchaseContractChangeChangeCreate',
    component: () => import('@/views/Purchase/Contract/Change/ChangeCreate.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/change/complete',
    name: 'PurchaseContractChangeComplete',
    component: () => import('@/views/Purchase/Contract/Change/Complete.vue'),
    meta: { ownerOnly: true },
  },
  {
    path: '/purchase/contract/change/cancel',
    name: 'PurchaseContractChangeCancel',
    component: () => import('@/views/Purchase/Contract/Change/Cancel.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/purchase/contract/change/error',
    name: 'PurchaseContractChangelError',
    component: () => import('@/views/Purchase/Contract/Change/Error.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/group-adjustment',
    name: 'GroupAdjustment',
    component: () => import('@/views/GroupAdjustment.vue'),
    meta: {
      ownerOnly: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/authentication',
    name: 'UserAuthentication',
    component: () => import('@/views/UserAuthentication.vue'),
    meta: { noAllAuth: true },
  },
  {
    path: '/account-registration',
    name: 'AccountRegistration',
    component: () => import('@/views/AccountRegistration/AccountRegistration.vue'),
    meta: { noAllAuth: true },
  },
  {
    path: '/account-registration/complete',
    name: 'AccountRegistrationComplete',
    component: () => import('@/views/AccountRegistration/AccountRegistrationComplete.vue'),
    meta: { noAllAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { noAllAuth: true },
  },
  {
    path: '/expiration-of-contract',
    name: 'ExpirationOfContract',
    component: () => import('@/views/Error/ExpirationOfContract.vue'),
    meta: {
      ownerOnly: true,
      noExpirationCheck: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/over-max-groups',
    name: 'OverMaxGroups',
    component: () => import('@/views/Error/OverMaxGroups.vue'),
    meta: {
      ownerOnly: true,
      noGroupCheck: true,
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error/Error.vue'),
    meta: { noAllAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
})

// TODO: 整理したい
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.ownerOnly)) {
    if (to.matched.some((record) => record.meta.noAllAuth)) {
      next()
      return
    } else if (to.matched.some((record) => record.meta.noExpirationCheck && record.meta.noGroupCheck)) {
      await authStore.checkLoggedIn()
    } else if (to.matched.some((record) => record.meta.noGroupCheck)) {
      await authStore.checkLoggedInExpiration()
    } else {
      await authStore.checkAll()
    }
  } else {
    if (to.matched.some((record) => record.meta.noAllAuth)) {
      next()
      return
    }

    await orgUserAuthStore.checkLoggedIn()
    await orgUserAuthStore.checkContract()
  }

  (router.app as any).$gtag.set({ user_id: authStore.orgLoginId })
  if (from.matched.some((record) => record.meta.gtag)) {
    (router.app as any).$gtag.event(from.name)
  }
  next()
})

export default router
