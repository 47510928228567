import Chart from 'chart.js'

/**
 * Chart.jsのcanvasの中心に文字を表示するプラグイン
 *
 * 使用方法:
 *
 *      options: {
 *         elements: {
 *           center: {
 *             text: 'Red is 2/3 of the total numbers',
 *             color: '#FF6384', // Default is #000000
 *             fontStyle: 'Arial', // Default is Arial
 *             sidePadding: 20, // Default is 20 (as a percentage)
 *             minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
 *             lineHeight: 25 // Default is 25 (in px), used for when text wraps
 *           }
 *         }
 *       }
 */
Chart.pluginService.register({
  beforeDraw: function (chart: Chart & { controller?: { innerRadius?: number } }) {
    if (chart.config.options?.plugins?.center && chart.ctx) {
      const ctx = chart.ctx
      const innerRadius = chart.controller?.innerRadius || 0

      // optionsから中央表示の情報を取得
      const centerConfig = chart.config.options.plugins.center
      const fontStyle = centerConfig.fontStyle || 'Arial'
      const txt = centerConfig.text
      const color = centerConfig.color || '#000'
      const maxFontSize = centerConfig.maxFontSize || 75
      const sidePadding = centerConfig.sidePadding || 20
      const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)

      // フォントの初期化
      ctx.font = '30px ' + fontStyle

      // テキストの表示幅とグラフの幅を取得
      const stringWidth = ctx.measureText(txt).width
      const elementWidth = innerRadius * 2 - sidePaddingCalculated

      // フォントがどれだけ大きくなるかを計算
      const widthRatio = elementWidth / stringWidth
      const newFontSize = Math.floor(30 * widthRatio)
      const elementHeight = innerRadius * 2

      // ラベルの高さを超えないように制限
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
      let minFontSize = centerConfig.minFontSize
      const lineHeight = centerConfig.lineHeight || 25
      let wrapText = false

      if (minFontSize === undefined) {
        minFontSize = 20
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize
        wrapText = true
      }

      // 描画設定
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
      let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
      ctx.font = fontSizeToUse + 'px ' + fontStyle
      ctx.fillStyle = color

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY)
        return
      }

      const words = txt.split(' ')
      let line = ''
      const lines = []

      // 改行設定
      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > elementWidth && n > 0) {
          lines.push(line)
          line = words[n] + ' '
        } else {
          line = testLine
        }
      }

      // 縦の中心位置の調整
      centerY -= (lines.length / 2) * lineHeight

      for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY)
        centerY += lineHeight
      }

      //描画
      ctx.fillText(line, centerX, centerY)
    }
  },
})
