




import organizationUsersStore from '@/store/OrganizationUsers'
import authStore from '@/store/Auth'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  $gtag: any
  $router: any
  async logout(): Promise<void> {
    if (this.$router.history.current.meta.gtag) {
      this.$gtag.set({ user_id: authStore.orgLoginId })
      this.$gtag.event(this.$router.history.current.name)
    }
    await organizationUsersStore.logout()
    this.$router.replace({ name: 'Login' })
  }
}
