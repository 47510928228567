









import { Component, Vue } from 'vue-property-decorator'
import menuStore from '@/store/Menu'

@Component({})
export default class extends Vue {
  get isMenuVisible(): boolean {
    return menuStore.isVisible
  }
}
